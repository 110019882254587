<template>
  <div class="header-top">
    <div class="container">
      <div class="row no-gutters justify-content-between">
        <div class="header-top__logo">
          <a :href="baseUrlWithLocale" rel="home">
            <img :src="require('@javascript/packs/images/@rrb/main/logo-dark.svg')" alt="Банк РРБ" />
          </a>
        </div>
        <div class="header-top__menu d-none d-md-flex">
          <div class="header-top__menu--number">
            <img :src="require('@javascript/packs/images/@rrb/main/icons/mobile.svg')" alt="mobile" />
            <div>
              <div class="mobile-call">{{ t[locale]['rrb.header.contact'] }}</div>
              <a href="tel:+375173370202">+375 17 337 02 02</a>
            </div>
          </div>
          <div class="header-top__menu--button-enter" v-if="!isLoginPage && !isLogin">
            <LoginModal :locale="locale" :base-url-with-locale="baseUrlWithLocale"/>
          </div>
          <div class="header-top__menu--button-user">
            <menu-desktop-button
              :is-login="isLogin"
              :user-name="userName"
              :profile-menu="profileMenu"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
              :phones="phones"
            />
          </div>
          <bottom-menu-logged-in
            :is-login="isLogin"
            v-if="isLogin"
            :locale="locale"
            :default-locale="defaultLocale"
            :base-url-with-locale="baseUrlWithLocale"
            :user-data="userData"
            :phones="phones"
          />
          <div v-if="!isOrderPage" class="header-top__menu--lang">
            <language
              :available-locales="availableLocales"
              :current-path="currentPath"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
            />
          </div>
        </div>
        <div class="header-top__menu-mobile d-md-none">
          <cart
            v-if="!isOrderPage && isLogin"
            :locale="locale"
            :default-locale="defaultLocale"
            :user-id="userData.id"
            :base-url-with-locale="baseUrlWithLocale"
          />
          <div v-if="!isOrderPage" class="header-top__menu--lang">
            <language
              :available-locales="availableLocales"
              :current-path="currentPath"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
            />
          </div>
            <menu-mobile-button
              v-if="isHome"
              :is-login="isLogin"
              :user-name="userName"
              :user-data="userData"
              :profile-menu="profileMenu"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
              :available-locales="availableLocales"
              :current-path="currentPath"
              :phones="phones"
              :is-home-page="isHomePage"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AUTHORIZATION_LOGIN, ORDER_SHOW } from '@javascript/config/routes'
import { scrollToElementById } from '@javascript/utils/helper-methods'
import VlButton from '@components/@exim/ui/vl-button'
import MenuDesktopButton from './menu-button/menu-desktop-button/MenuDesktopButton'
import MenuMobileButton from './menu-button/menu-mobile-button/MenuMobileButton'
import Language from './language-container/LanguageContainer'
import Cart from './cart/Cart'
import locales from './locales'
import { redirectTo } from '../../../utils/helper-methods'
import BottomMenuLoggedIn from './menus/bottom-menu-logged-in/BottomMenuLoggedIn.vue'
import LoginModal from './LoginModal.vue'
export default {
  name: 'HeaderTop',
  components: {
    LoginModal,
    BottomMenuLoggedIn,
    VlButton,
    MenuDesktopButton,
    MenuMobileButton,
    Cart,
    Language,
  },
  props: {
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    userData: {
      type: Object,
      default: null,
    },
    phones: {
      type: Array,
      default: [],
    },
    isHomePage: {
      type: String,
    },
  },
  data: () => ({
    t: locales,
  }),
  computed: {
    isHome() {
      return JSON.parse(this.isHomePage)
    },
    isLoginPage() {
      return window.location.pathname.includes(AUTHORIZATION_LOGIN)
    },
    isOrderPage() {
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    callCenterPhone() {
      if (Array.isArray(this.phones) && this.phones[0]) {
        return this.phones[0]
      }
      return ''
    },
  },
  methods: {
    moveTo() {
      try {
        scrollToElementById('login')
      } catch (e) {
        redirectTo(`${this.baseUrlWithLocale}${AUTHORIZATION_LOGIN}`)
      }
    },
  },
}
</script>
