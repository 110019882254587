<template>
  <footer class="footer" id="contacts">
    <footer-top :phones="footerPhone" :locale="locale" :second-menu="secondMenu" :first-menu="footerMenu" />
<!--    <hr class="mt-0 mb-0" />-->
<!--    <footer-middle-->
<!--      :first-menu="footerMenu"-->
<!--      :second-menu="secondMenu"-->
<!--      :email="footerEmail"-->
<!--      :locale="locale"-->
<!--    />-->
    <footer-bottom
      :locale="locale"
      :social-networks="socialNetworks"
      :footer-copyright="footerCopyright"
      :user-token="userToken"
    />
  </footer>
</template>

<script>
import FooterTop from './footer-top'
import FooterMiddle from './footer-middle'
import FooterBottom from './footer-bottom'
import locales from './locales'
export default {
  name: 'Footer',
  components: {
    FooterTop,
    FooterMiddle,
    FooterBottom,
  },
  props: {
    footerMenu: {
      type: Object,
      default: null,
    },
    footerCopyright: {
      type: String,
      default: null,
    },
    footerPhone: {
      type: Array,
      default: [],
    },
    footerEmail: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    userToken: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      t: locales,
      secondMenu: [
        {
          url: 'https://www.priorbank.by/offers/services/currency-exchange',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.currency'
            ],
        },
        {
          url: 'https://www.prior.by/maps',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.branches'
            ],
        },
        {
          url: 'https://www.priorbank.by/priorbank-main/press-center/news',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.news'
            ],
        },
        {
          url: 'https://www.priorbank.by/offers/importantly/financial-literacy',
          title:
            locales[this.locale][
              'priorbank.footer.information_menu.menus_item.regulations'
            ],
        },
      ],
      socialNetworks: [
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/facebook.svg'),
          link: 'https://www.facebook.com/bankminsk',
        },
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/vk.svg'),
          link: 'https://vk.com/bankrrb',
        },
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/instagram.svg'),
          link: 'https://www.instagram.com/bank__rrb',
        },
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/youtube.svg'),
          link: 'https://www.youtube.com/channel/UC1whWIqJfNtgv_8ju4bPjSw',
        },
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/tiktok.svg'),
          link: 'https://www.tiktok.com/@bankrrb?lang=ru-RU',
        },
        {
          logoSrc: require('@javascript/packs/images/@rrb/main/icons/footer/x.svg'),
          link: 'https://x.com/rrbbank',
        },
      ],
    }
  },
}
</script>
