<script>
import AppHeader from '@components/@rrb/header'
import PageHeader from '@components/@rrb/page-header'
import AppFooter from '@components/@rrb/footer'
import NotificationsList from '@components/@rrb/ui/vl-notification'
import setValidateMessages from '@javascript/plugins/localization/veeValidateMessage'

export default {
  name: 'App',
  provide: {
    locale: 'ru',
    defaultLocale: 'ru',
    baseUrlWithLocale: '',
    isGuest: true,
  },
  components: {
    AppHeader,
    PageHeader,
    AppFooter,
    NotificationsList,
    PersonalProfile: () =>
      import(
        '@javascript/pages/profile/personal-profile/rrb/PersonalProfile'
      ),
    PersonalProfileEdit: () =>
      import(
        '@javascript/pages/profile/personal-profile-edit/rrb/PersonalProfileEdit'
      ),
    PersonalMyBalance: () =>
      import(
        '@javascript/pages/profile/personal-my-balance/rrb/PersonalMyBalance'
      ),
    RegistrationDetails: () =>
      import(
        '@javascript/pages/registration/details/rrb/RegistrationDetails'
      ),
    PersonalCards: () =>
      import(
        '@javascript/pages/profile/personal-cards/rrb/PersonalCards'
      ),
    PersonalAddCard: () =>
      import(
        '@javascript/pages/profile/personal-add-card/rrb/PersonalAddCard'
      ),
    PersonalOrders: () =>
      import(
        '@javascript/pages/profile/personal-my-orders/rrb/PersonalMyOrders'
      ),
    AuthorizationLogin: () =>
      import('@javascript/pages/authorization/login/rrb/Login.vue'),
    PersonalChangePassword: () =>
      import(
        '@javascript/pages/profile/personal-change-password/rrb/PersonalChangePassword'
      ),
    ForgotPasswordOption: () =>
      import(
        '@javascript/pages/restoration/forgot-password/reset-options/rrb/ForgotPasswordOptions'
      ),
    VerificationCode: () =>
      import(
        '@javascript/pages/restoration/forgot-password/verification-code/rrb/VerificationCode'
      ),
    CreateNewPassword: () =>
      import(
        '@javascript/pages/restoration/forgot-password/new-password/rrb/NewPassword'
      ),
    EmailVerifying: () =>
      import(
        '@javascript/pages/layouts/rrb/email-verifying/EmailVerifying'
      ),
    OrderSuccess: () =>
      import('@javascript/pages/order/success/rrb/OrderSuccess'),
    OrderPage: () => import('@javascript/pages/order/show/rrb/Order'),
    CatalogPage: () => import('@javascript/pages/catalog/rrb/Catalog'),
    ProductPage: () => import('@javascript/pages/product/rrb/ProductPage'),
    ProductAddress: () =>
      import('@javascript/pages/product-address/rrb/ProductAddress'),
    PersonalMyWishList: () =>
      import(
        '@javascript/pages/profile/personal-my-wish-list/rrb/PersonalMyWishList'
      ),
    PersonalChangeEmail: () =>
      import(
        '@javascript/pages/profile/personal-change-email/rrb/PersonalChangeEmail'
      ),
    MainPage: () => import('@javascript/pages/main/rrb/Main'),
    SessionPopup: () =>
      import('@javascript/pages/layouts/rrb/session-popup/SessionPopup'),
    CashBack: () => import('@javascript/pages/cashback/rrb/CashBack'),
    CashBackShow: () =>
      import('@javascript/pages/cashback/rrb/CashBackShow'),
    VlPopup: () => import('@javascript/pages/profile/personal-profile/popup'),
  },
  methods: {
    setLocalesSeting() {
      this._provided.locale = this.$el.attributes.locale.value
      this._provided.defaultLocale = this.$el.attributes.defaultLocale.value
      this._provided.baseUrlWithLocale = this.$el.attributes.base_url_with_locale.value
      this._provided.isGuest = !JSON.parse(this.$el.attributes.is_guest.value)
      setValidateMessages(this._provided.locale)
    },
  },
  mounted() {
    this.setLocalesSeting()
  },
}
</script>
