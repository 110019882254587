<template>
  <div class="menu-mobile-button">
    <div class="menu-mobile-button__btn" @click="toggleMobileMenu">
      <span></span>
    </div>
<!--    <menu-mobile-logged-in-->
<!--      v-if="visibleMenuLoggedIn"-->
<!--      :user-name="userName"-->
<!--      :show-mobile-menu="showMobileMenu"-->
<!--      :toggle-mobile-menu="toggleMobileMenu"-->
<!--      :locale="locale"-->
<!--      :profile-menu="profileMenu"-->
<!--      :base-url-with-locale="baseUrlWithLocale"-->
<!--      :available-locales="availableLocales"-->
<!--      :current-path="currentPath"-->
<!--      :user-data="userData"-->
<!--      :phones="phones"-->
<!--    />-->
    <menu-mobile-logged-out
      :show-mobile-menu="showMobileMenu"
      :toggle-mobile-menu="toggleMobileMenu"
      :locale="locale"
      :base-url-with-locale="baseUrlWithLocale"
      :available-locales="availableLocales"
      :current-path="currentPath"
      :phones="phones"
      @clickByNav="clickByNav"
      :is-home-page="isHomePage"
    />
  </div>
</template>
<script>
import MenuMobileLoggedIn from './MenuMobileLoggedIn'
import MenuMobileLoggedOut from './MenuMobileLoggedOut'
import { scrollToElementById } from '@javascript/utils/helper-methods'
import locales from '../locales'
export default {
  name: 'MenuMobileButton',
  components: {
    MenuMobileLoggedIn,
    MenuMobileLoggedOut,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: false,
    },
    locale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    userData: {
      type: Object,
      default: null,
    },
    phones: {
      type: Array,
      default: () => [],
    },
    isHomePage: {
      type: String,
    },
  },
  data: () => ({
    t: locales,
    showMobileMenu: false,
  }),
  computed: {
    visibleMenuLoggedIn() {
      return this.showMobileMenu && this.isLogin
    },
    visibleMenuLoggedOut() {
      return this.showMobileMenu && !this.isLogin
    },
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    clickByNav(elementId) {
      this.toggleMobileMenu()
      scrollToElementById(elementId)
    },
  },
}
</script>
