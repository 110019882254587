<template>
  <div class="footer-top">
    <div class="container">

      <div class="footer-top__desktop">
        <div>
          <img :src="require('@javascript/packs/images/@rrb/main/logo.svg')" alt="Банк РРБ">
        </div>
        <div class="footer-top__desktop-wrap">
          <div class="contacts">
            <div class="contacts__phone">
              <div class="img_wrap">
                <img :src="require('@javascript/packs/images/@rrb/main/icons/phone.svg')" alt="phone" />
              </div>
              <div>
                <div class="mobile-call">{{ t[locale]['rrb.header.contact'] }}</div>
                <a href="tel:+375173370202">+375 17 337 02 02</a>
                <div class="location">
                  <div>{{ t[locale]['rrb.footer.location_title'] }}</div>
                  <div class="location__address">
                    <span>{{ t[locale]['rrb.footer.location_city'] }}</span>
                    <span>{{ t[locale]['rrb.footer.location_street'] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="contacts__mail">
              <div class="img_wrap">
                <img :src="require('@javascript/packs/images/@rrb/main/icons/mail.svg')" alt="mail" />
              </div>
              <a href="mailto:ritm@rrbbank.by">ritm@rrbbank.by</a>
            </div>
            <div class="contacts__site">
              <div class="img_wrap">
                <img :src="require('@javascript/packs/images/@rrb/main/icons/site.svg')" alt="site" />
              </div>
              <a href="https://www.rrb.by/" target="_blank">www.rrb.by</a>
            </div>
          </div>

<!--          <div class="location">-->
<!--            <div>{{ t[locale]['rrb.footer.location_title'] }}</div>-->
<!--            <div class="location__address">-->
<!--              <span>{{ t[locale]['rrb.footer.location_city'] }}</span>-->
<!--              <span>{{ t[locale]['rrb.footer.location_street'] }}</span>-->
<!--            </div>-->
<!--          </div>-->

          <div class="footer-menu">
            <ul class="nav-menu" v-if="firstMenu">
              <li v-for="(item, index) in firstMenu.children" :key="index">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
              </li>
            </ul>
          </div>

            <a href="https://t.me/RRB_Bank_Bot" class="telegram-bot" target="_blank">
              <img :src="require('@javascript/packs/images/@rrb/main/icons/telegram-bot.svg')" alt="Telegram Bot">
              <span>Telegram Bot</span>
            </a>
        </div>
      </div>

      <div class="footer-top__mobile">
        <div class="footer-top__mobile-wrap">
          <div class="footer-top__mobile-wrap__top">
            <div>
              <img class="rrb_logo" :src="require('@javascript/packs/images/@rrb/main/logo.svg')" alt="Банк РРБ">
            </div>

            <div class="contacts">
              <div class="contacts__phone">
                <img :src="require('@javascript/packs/images/@rrb/main/icons/mobile.svg')" alt="mobile" />
                <div>
                  <div class="mobile-call">{{ t[locale]['rrb.header.contact'] }}</div>
                  <a href="tel:+375173370202">+375 17 337 02 02</a>
                </div>
              </div>
              <div class="contacts__mail">
                <img :src="require('@javascript/packs/images/@rrb/main/icons/mail.svg')" alt="mail" />
                <a href="mailto:ritm@rrbbank.by">ritm@rrbbank.by</a>
              </div>
            </div>
          </div>

          <div class="pages">
<!--            <ul class="nav-menu" v-if="secondMenu">-->
<!--              <li v-for="(item, index) in secondMenu" :key="index">-->
<!--                <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>-->
<!--              </li>-->
<!--            </ul>-->
            <ul class="nav-menu" v-if="firstMenu">
              <li v-for="(item, index) in firstMenu.children" :key="index">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
              </li>
            </ul>
          </div>

          <div class="footer-top__mobile-wrap__bottom">
            <div class="licence">{{ t[locale]['rrb.footer.licence'] }} <br>
              © Copyright  {{renderCurrentYear}}. {{ t[locale]['rrb.footer.copyright'] }}</div>
            <a href="https://t.me/RRB_Bank_Bot" class="telegram-bot" target="_blank">
              <img :src="require('@javascript/packs/images/@rrb/main/icons/telegram-bot.svg')" alt="Telegram Bot">
              <span>Telegram Bot</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VlIconBase from '@components/@priorbank/ui/vl-icons'
import VlIconPhone from '@components/@priorbank/ui/vl-icons/VlIconPhone'
import locales from '../locales'
export default {
  name: 'FooterContacts',
  components: {
    VlIconBase,
    VlIconPhone,
  },
  props: {
    phones: {
      type: Array,
      default: [],
    },
    secondMenu: {
      type: [Array, Object],
      default: null,
    },
    firstMenu: {
      type: [Array, Object],
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  computed: {
    callCenterPhone() {
      if (Array.isArray(this.phones) && this.phones[0]) {
        return this.phones[0]
      }
      return ''
    },
    renderCurrentYear() {
      return new Date().getFullYear()
    },
    supportPhone() {
      if (Array.isArray(this.phones) && this.phones[1]) {
        return this.phones[1]
      }
      return ''
    },
    mobilePhone() {
      if (Array.isArray(this.phones) && this.phones[2]) {
        return this.phones[2]
      }
      return ''
    },
  },
}
</script>
