<template>
  <div class="cart">
    <div class="cart-content">
      <form :action="orderRouter" method="post">
        <input type="hidden" name="authenticity_token" :value="token" />
        <template v-if="cartProducts.length">
          <div class="cart-items mb-3">
            <transition-group name="fade" tag="div" class="cart-items__list">
              <cart-item v-for="product of cartProducts" :key="product.id" :product="product" />
            </transition-group>
<!--            <div class="d-md-none d-block mt-4">-->
<!--              <div class="cart-total mb-4">-->
<!--                <div class="cart-total__text">{{ t[locale]['cart.total'] }}</div>-->
<!--                <div class="cart-total__amount">-->
<!--                  {{ cartTotalPrice }}-->
<!--                  <span class="cart-total__currency">{{ showTitleBonuses }}</span>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div v-for="product of cartProducts" :key="product.id">-->
<!--                <input type="hidden" name="items[][id]" v-model="product.id" />-->
<!--                <input type="hidden" name="items[][quantity]" v-model="product.quantity" />-->
<!--              </div>-->
<!--              <vl-button class="w-100" size="large" type="primary" actionType="submit">-->
<!--                {{ t[locale]['cart.order'] }}-->
<!--              </vl-button>-->
<!--            </div>-->
          </div>
          <div class="d-md-block ">
            <div class="cart-total mb-4">
              <div class="cart-total__text">{{ t[locale]['cart.total'] }}</div>
              <div class="cart-total__amount">
                {{ cartTotalPrice }}
                <span class="cart-total__currency">{{ showTitleBonuses }}</span>
              </div>
            </div>
            <div v-for="product of cartProducts" :key="product.id">
              <input type="hidden" name="items[][id]" v-model="product.id" />
              <input type="hidden" name="items[][quantity]" v-model="product.quantity" />
            </div>
            <vl-button class="w-100" size="medium" type="primary" actionType="submit" :loading="isLoading">
              {{ t[locale]['cart.order'] }}
            </vl-button>
          </div>
        </template>
        <template v-else>
          <span class="cart__info-text">{{ t[locale]['cart.cart_empty'] }}</span>
        </template>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CartItem from './components/CartItem'
import VlButton from '@components/@rrb/ui/vl-button'
import filters from '@javascript/filters/filters'
import { ORDER_SHOW } from '@javascript/config/routes'
import authenticityToken from '@javascript/services/authenticityTokenService'

import locales from './locales'

const PRODUCTS_KEY = 'products_u_'

export default {
  name: 'Cart',
  components: {
    CartItem,
    VlButton,
  },
  props: {
    userId: {
      type: [String, Number],
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    token: authenticityToken(),
    t: locales,
  }),
  computed: {
    ...mapGetters({
      cartProducts: 'cart/cartProducts',
      cartTotalPrice: 'cart/cartTotalPrice',
      isLoading: 'cart/isLoading',
    }),
    showTitleBonuses() {
      return filters.serializePoint(this.cartTotalPrice)
    },
    orderRouter() {
      return `${this.baseUrlWithLocale}${ORDER_SHOW}`
    },
  },
  methods: {
    ...mapActions({
      API_recalculate: 'cart/API_recalculate',
    }),

    ...mapMutations({
      initialiseStore: 'cart/initialiseStore',
      setUserId: 'cart/setUserId',
    }),
    getItemsFromLocalstorage(storageKey) {
      const items = JSON.parse(window.localStorage.getItem(storageKey))
      return items
    },
    setItemsToLocalstorage(state) {
      window.localStorage.setItem(`${PRODUCTS_KEY}${this.userId}`, JSON.stringify(state.cart.items))
    },
    initStore(store) {
      const products = JSON.parse(window.localStorage.getItem(`${PRODUCTS_KEY}${this.userId}`))
      this.initialiseStore(products)
    },
    updateStore(data) {
      if (data.key === `${PRODUCTS_KEY}${this.userId}`) {
        const product = JSON.parse(data.newValue)
        this.initialiseStore(product)
      }
      return false
    },
    subscribeVuex() {
      this.$store.subscribe((mutation, state) => {
        this.setItemsToLocalstorage(state)
      })
    },
  },
  created() {
    const data = this.getItemsFromLocalstorage(`${PRODUCTS_KEY}${this.userId}`)
    this.initStore(data)
    this.setUserId(this.userId)
    this.subscribeVuex()
    window.addEventListener('storage', data => {
      this.updateStore(data)
    })
  },
  async mounted() {
    await this.API_recalculate()
  },
}
</script>
