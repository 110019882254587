<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.03516 1.36377H31.0352V31.1583H1.03516V1.36377Z"
      :stroke="setColorToStroke"
      :stroke-width="setStrokeWidth"
    />
    <rect
      x="9.03516"
      y="15.2678"
      width="14"
      height="1.9863"
      rx="0.993151"
      :fill="setColorToFill"
    />
    <rect
      x="17.0352"
      y="9.30908"
      width="13.9041"
      height="2"
      rx="1"
      transform="rotate(90 17.0352 9.30908)"
      :fill="setColorToFill"
    />
  </g>
</template>
<script>
const DEFAULT_STROKE_WIDTH = '2'
export default {
  name: 'VlIconPlus',
  props: {
    fill: {
      type: [String, Array],
      default: '#fff',
    },
    stroke: {
      type: [String, Array],
      default: '#fff',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setColorToFill() {
      if (typeof this.fill === 'string') {
        return this.fill
      }
      return this.fill[0]
    },
    setColorToStroke() {
      if (typeof this.stroke === 'string') {
        return this.stroke
      }
      return this.stroke[0]
    },
    setStrokeWidth() {
      return this.disabled ? '0' : DEFAULT_STROKE_WIDTH
    },
  },
}
</script>
