<template>
  <div class="page-header">
    <div class="container">
      <h2 class="h2-title" v-html="title"></h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: 'Default page title',
    },
  },
}
</script>
