<template>
  <div class="col-auto align-items-center balance-info">
    <div class="d-flex flex-md-row align-items-center w-100 balance-info__content">
      <menu-desktop-button-logged-in
        :locale="locale"
        :user-name="userName"
        :profile-menu="profileMenu"
        :phones="phones"
      />
      <div class="header__user-balance">
        <span>{{ t[locale]['priorbank.header.header_botom_menu.your_balance'] }}</span>

        <div class="header__user-balance__value" >
          <span>{{ balance }}</span>
          <span class="site-currency">{{currency}}</span>

          <div class="bonuses-expire" v-if="userData.bonuses_will_expire > 0">
            <vl-tooltip :isCloseBtn="true">
              <template #activator="on">
                <div v-on="on" class="d-flex">
                  <vl-icon-base width="14" height="14" viewBoxWidth="14" viewBoxHeight="14" opacity="1">
                    <vl-icon-expired-mark />
                  </vl-icon-base>
                </div>
              </template>
              <template>
                <div>
                  {{ t[locale]['rrb.bonuses_can_expire'].replace('{bonuses}', userData.bonuses_will_expire) }}
                  <a :href="rulesPage" target="_blank">{{ t[locale]['profile.my_balance.rules'] }}</a>.
                </div>
              </template>
            </vl-tooltip>
          </div>

        </div>

      </div>
      <cart
        v-if="!isOrderPage"
        :locale="locale"
        :default-locale="defaultLocale"
        :user-id="userData.id"
        :base-url-with-locale="baseUrlWithLocale"
      />
    </div>
  </div>
</template>
<script>
import Cart from '../../cart/Cart'
import VlTooltip from '@components/@rrb/ui/vl-tooltip'
import VlIconBase from '@components/@rrb/ui/vl-icons'
import VlIconExclamationMark from '@components/@rrb/ui/vl-icons/VlIconeExclamationMark'
import VlIconExpiredMark from '@components/@rrb/ui/vl-icons/VlIconExpiredMark'
import { ORDER_SHOW } from '@javascript/config/routes'
import locales from './locales'
import MenuDesktopButton from '../../menu-button/menu-desktop-button/MenuDesktopButton.vue'
import MenuDesktopButtonLoggedIn from '../../menu-button/menu-desktop-button/MenuDesktopButtonLoggedIn.vue'

export default {
  name: 'BottomMenuLoggedIn',
  components: {
    MenuDesktopButtonLoggedIn,
    MenuDesktopButton,
    Cart,
    VlTooltip,
    VlIconBase,
    VlIconExclamationMark,
    VlIconExpiredMark
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
      default: null,
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      // default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    phones: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      t: locales,
    }
  },
  computed: {
    balance() {
      return this.userData?.balance
    },
    currency() {
      return this.userData?.site_currency
    },
    isOrderPage() {
      // return window.location.pathname.includes(ORDER_SHOW)
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    rulesPage() {
      return `${this.baseUrlWithLocale}/topic/rules`
    },
  },
}
</script>
