<template>
  <ul v-if="isLogin && !isShow" class="header-bottom__menu--items">
    <li>
      <a :href="catalogRouter">{{ t[locale]['priorbank.header.header_botom_menu.catalog'] }}</a>
    </li>
  </ul>
  <div v-else-if="isShow" class="home-menus">
    <ul class="header-bottom__menu--items">
      <li @click="clickHandler(navItem)" v-for="navItem in navItems" :key="navItem.value">
        {{ navItem.name }}
      </li>
    </ul>
  </div>
</template>
<script>
import { CATALOG, MAIN as HomeRouter } from '@javascript/config/routes'
import locales from './locales'

export default {
  name: 'BottomMenuLoggedOut',
  props: {
    locale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      t: locales,
      navItems: [
        {
          name: locales[this.locale]['rrb.header.header_menu.earning_points'],
          value: 'earning-points',
        },
        {
          name: locales[this.locale]['rrb.header.header_menu.opportunities'],
          value: 'catalog',
        },
        {
          name: locales[this.locale]['rrb.header.header_menu.partners'],
          value: 'partners',
        },
        {
          name: locales[this.locale]['rrb.header.header_menu.how_to_join'],
          value: 'involved',
        },
        {
          name: locales[this.locale]['rrb.header.header_menu.contacts'],
          value: 'contacts',
        },
      ],
    }
  },
  computed: {
    catalogRouter() {
      return `${this.baseUrlWithLocale}${CATALOG}`
    },
    isHomeRouter() {
      const currentRouter = document.location.pathname
      if (currentRouter === HomeRouter) {
        return true
      }
      return false
    },
  },
  methods: {
    clickHandler(item) {
      this.$emit('clickHandler', item)
    },
  },
}
</script>
