<template>
  <div>
    <a href="#" @click.prevent="toggleModal(true)" class="desktop_login">
      {{ t[locale]['priorbank.header.header_top_menu.enter'] }}
    </a>
    <vl-button size="medium" type="third" class="mobile_login" @click.native="toggleModal(true)">
      {{ t[locale]['priorbank.header.header_top_menu.enter'] }}
    </vl-button>
    <vl-modal
      :close-outside="false"
      :open="visibleModal"
      @close="toggleModal(false)"
      title-transform="none"
      max-width="480"
      :footerOffsetTop="10"
      class="modal-login-form"
    >
      <template #header>
        <div class="login_title__wrap">
          <img :src="require('@javascript/packs/images/@rrb/main/modal-login-title.png')" alt="login form">
        </div>
      </template>
      <div class="content-wrapper">
        <div class="login-form-wrapper">
          <validation-observer ref="form" v-slot="{ handleSubmit }">
            <form class="login-form" @submit.prevent="handleSubmit(login)">
              <validation-provider mode="eager" rules="required" v-slot="{ errors, failed }" vid="email">
                <div class="mb-3">
                  <vl-input-text
                    v-model="user.email"
                    :error="failed"
                    type="email"
                    name="user[email]"
                    :label="t[locale]['rrb.login.email']"
                    onColorBg
                  />
                  <span class="error-message">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                mode="eager"
                rules="required"
                v-slot="{ errors, failed }"
                vid="password"
              >
                <div class="mb-3">
                  <vl-input-text
                    v-model="user.password"
                    :error="failed"
                    type="password"
                    name="user[password]"
                    :label="t[locale]['rrb.login.password']"
                    onColorBg
                  />
                  <span class="error-message">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <div class="d-flex justify-content-center mt-4">
                <vl-button
                  size="large"
                  type="primary"
                  actionType="submit"
                  :loading="loadingButton"
                >
                  {{ t[locale]['modals.new_password_chanched.button'] }}
                </vl-button>
              </div>
            </form>
          </validation-observer>
          <div class="d-flex flex-column align-items-center mt-4 login-links">
            <a class="login-form__url" :href="resetPasswordRouter()">
              {{ t[locale]['authorization.login.forgot_password_or_login'] }}
            </a>
            <a class="login-form__url" :href="registrationRouter()">
              {{ t[locale]['authorization.login.not_registration'] }}
            </a>
          </div>
        </div>
      </div>
    </vl-modal>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import VlInputText from '@components/@rrb/ui/vl-input-text'
import checkErrors from '@javascript/mixins/checkErrors'
import VlButton from '@components/@rrb/ui/vl-button'
import VlModal from '@components/@rrb/ui/vl-modal'

import {
  RESTORATION_RESETOPTION,
  REGISTRATION_CARD,
} from '@javascript/config/routes'
import {
  removeLocalStorage,
  redirectTo,
} from '@javascript/utils/helper-methods'
import locales from './locales'
export default {
  name: "LoginModal",
  components: {
    VlButton,
    VlModal,
    VlInputText,
  },
  mixins: [checkErrors],
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    visibleModal: false,
    t: locales,
    user: {
      email: null,
      password: null,
    },
    loadingButton: false,
  }),
  computed: {
    ...mapGetters({
      getFormErrors: 'authorization/getFormErrors',
    }),
  },
  methods: {
    toggleModal(value) {
      this.visibleModal = value
    },
    ...mapActions({
      API_LOGIN: 'authorization/API_LOGIN',
    }),
    async login() {
      this.toggleLoadingButton(true)
      try {
        const { data } = await this.API_LOGIN({
          ...this.user,
          id: this.providerId,
        })
        removeLocalStorage('email_confirmed')
        removeLocalStorage('thank_cancel')
        redirectTo(data.redirect_to)
      } catch (error) {
        this.showAllErrors(error, false, 3000)
        this.showFormErrors()
      } finally {
        this.toggleLoadingButton(false)
      }
    },
    toggleLoadingButton(value) {
      this.loadingButton = value
    },
    showFormErrors() {
      this.$refs.form.setErrors({ email: [''], password: [''] })
    },
    resetPasswordRouter() {
      return `${this.baseUrlWithLocale}${RESTORATION_RESETOPTION}`
    },
    registrationRouter() {
      return `${this.baseUrlWithLocale}${REGISTRATION_CARD}`
    },
  },
}
</script>

