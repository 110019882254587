<template>
  <div
    class="header-bottom"
    :class="[
      { 'not-login': !isLogin },
      { 'is-login-page': hiddenOnThisPages },
      { 'is-login-page_catalog': isCatalogPage && isLogin },
    ]"
  >
    <div class="container">
      <!--      <div class="row justify-content-end flex-nowrap no-gutters align-items-center">-->
      <div class="row no-gutters header-bottom__menus">
        <div class="header-bottom__is_login">
          <!--          <menu-desktop-button-->
          <!--            :is-login="isLogin"-->
          <!--            :user-name="userName"-->
          <!--            :profile-menu="profileMenu"-->
          <!--            :locale="locale"-->
          <!--            :base-url-with-locale="baseUrlWithLocale"-->
          <!--            :phones="phones"-->
          <!--          />-->
          <bottom-menu-logged-in
            :is-login="isLogin"
            v-if="isLogin"
            :locale="locale"
            :default-locale="defaultLocale"
            :base-url-with-locale="baseUrlWithLocale"
            :profile-menu="profileMenu"
            :user-data="userData"
            :user-name="userName"
            :phones="phones"
          />
        </div>
        <bottom-menu-nav
          :is-login="isLogin"
          :base-url-with-locale="baseUrlWithLocale"
          :locale="locale"
          :is-show="showBottomMenu"
          @clickHandler="clickMenuHandler"
        />
        <div class="logout" v-if="!isLogin">
          <!--          <vl-button size="medium" type="third">-->
          <!--            {{ t[locale]['priorbank.header.header_top_menu.enter'] }}-->
          <!--          </vl-button>-->
          <LoginModal :locale="locale" />
          <vl-button size="medium" type="secondary" @click.native.stop="openRegistrationPage">
            <span>{{ t[locale]['priorbank.header.header_top_menu.register'] }}</span>
          </vl-button>
        </div>
        <!--        <div v-else>-->
        <!--          <bottom-menu-logged-in-->
        <!--            :is-login="isLogin"-->
        <!--            v-if="isLogin"-->
        <!--            :locale="locale"-->
        <!--            :default-locale="defaultLocale"-->
        <!--            :base-url-with-locale="baseUrlWithLocale"-->
        <!--            :user-data="userData"-->
        <!--            :phones="phones"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import {
  REGISTRATION_CARD,
  REGISTRATION_DETAILS,
  AUTHORIZATION_LOGIN,
  REGISTRATION_FAILURE,
  CATALOG,
} from '@javascript/config/routes'
import { scrollToElementById } from '@javascript/utils/helper-methods'
import BottomMenuLoggedIn from './menus/bottom-menu-logged-in/BottomMenuLoggedIn'
import BottomMenuNav from './menus/bottom-menu-nav/BottomMenuNav'
import VlButton from '@components/@rrb/ui/vl-button'
import { redirectTo } from '@javascript/utils/helper-methods'
import locales from './locales'
import MenuMobileButton from './menu-button/menu-mobile-button/MenuMobileButton.vue'
import MenuDesktopButton from './menu-button/menu-desktop-button/MenuDesktopButton.vue'
import LoginModal from './LoginModal.vue'

export default {
  name: 'HeaderBottom',
  components: {
    LoginModal,
    MenuDesktopButton,
    MenuMobileButton,
    BottomMenuLoggedIn,
    BottomMenuNav,
    VlButton,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: null,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
    phones: {
      type: Array,
      default: () => [],
    },
    isHomePage: {
      type: String,
    },
  },
  data() {
    return {
      t: locales,
    }
  },

  computed: {
    hiddenOnThisPages() {
      let pathName = window.location.pathname
      // console.log(pathName)
      return (
        pathName.includes(REGISTRATION_CARD) ||
        pathName.includes(REGISTRATION_DETAILS) ||
        pathName.includes(AUTHORIZATION_LOGIN) ||
        pathName.includes(REGISTRATION_FAILURE) ||
        pathName.includes(CATALOG)
      )
    },
    isCatalogPage() {
      return CATALOG === window.location.pathname || window.location.pathname.includes('catalog')
    },
    showBottomMenu() {
      // return !this.hiddenOnThisPages
      return JSON.parse(this.isHomePage)
    },
    isAuthorizationLoginPage() {
      if (AUTHORIZATION_LOGIN === window.location.pathname) {
        return true
      }
      return false
    },
  },
  methods: {
    clickMenuHandler({ value }) {
      scrollToElementById(value)
    },
    openRegistrationPage() {
      let link = `${this.baseUrlWithLocale}${REGISTRATION_CARD}`
      redirectTo(link)
    },
  },
}
</script>
