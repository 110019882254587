<template>
  <div class="basket-mobile" @click="toggleModalCard(true)">
    <div class="basket__link">
      <img class="basket__icon" :src="require('@javascript/packs/images/@rrb/main/icons/cart.svg')" alt="cart" />
      <div class="basket__count">{{ cartTotalCount }}</div>
    </div>
    <modal @click="toggleModalCard(false)" v-if="showCard" :margin-top="0" cancel-position="right">
      <hr>
      <div class="wrapper-cart mt-3">
        <cart
          :locale="locale"
          :defaultLocale="defaultLocale"
          :userId="userId"
          :base-url-with-locale="baseUrlWithLocale"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Cart from '@components/@rrb/cart'
import Modal from '../modal'

export default {
  name: 'CartMobile',
  components: {
    Modal,
    Cart,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    cartTotalCount: {
      type: [String, Number],
      default: 0,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCard: false,
    }
  },
  methods: {
    toggleModalCard(value) {
      // console.log(value)
      this.showCard = value
    },
  },
}
</script>
